
import Slider from "react-slick";

import BasketButton from "../BasketButton/BasketButton";
import RevealOnScroll from "../RevealOnScroll/RevealOnScroll";
import {GoBookmark} from "react-icons/go"
import { useCart } from "../CartContext/CartContext";

import './productCarousel.css'

const ProductCarousel = ({ products, settings, showImage, showTitle, showPrice, showCategory, showDescription, onBasketButton }) => {

  const { addToCart } = useCart(); // Получите доступ к функции addToCart из контекста корзины

  const handleAddToCart = (product) => {
    addToCart(product);
  };
  const url = 'https://api.chapanstore.com/media/'
  
  return (
    <div className="slider__container">
      <Slider {...settings}>
        {products.map((product) => (
            <div className="product__container"  key={product.id}>
              <div delay={.2} transform={'translateY(40px) scale(0.5)'} className="product__list-item" key={product.id}>
                <div className="product__img-container">{showImage && <img className="product__img" src={url + product.image['path_1']} alt={product.name} />}
                <div className="product__card-menu">
                  {/* <BasketButton product={product} onBasketButton={handleAddToCart} children={'Add to basket'} fontWeight={500}/> */}
                </div>
                </div>
  
                
                
              </div>
                  <div className="product__info-container">
                    {showTitle && <h4 className="product__title">{product.name}</h4>}
                    <div className="product__price-basket">
                    {showPrice && <p className="card__price">Цена: {product.price === null ? "договорная" : `${product.price} $`}</p>}
                      <BasketButton product={product} onBasketButton={handleAddToCart} children={<GoBookmark/>} fontWeight={500}/>
                      </div>
                   
                    {showCategory && <p className="product__category">Категория: {product.category}</p>}
                    {showDescription && <p className="product__info">Описание: {product.description}</p>}
                  </div>
            </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductCarousel;