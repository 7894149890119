
import { NavLink } from "react-router-dom"
import "./style.css"

import logo from '../../../src/images/Suraya_Collection_PNG-08.png'


import NavLinksAsdie from "../NavLinksAside/NavLinksAside";

const Navbar = () => {

      
    return (
        <nav className="navbar">
            <div className="navbar__container">
              <div className="nav__link">
                <NavLink to="/" className="nav__logo">
                  <img src={logo} alt="" />
                  {/* SURAYA <br/> COLLECTION */}
                </NavLink>
                <NavLink className="nav__link-main" to="/">Home</NavLink>
                <NavLink className="nav__link-main" to="/shop">Shop</NavLink>
                {/* <NavLink className="nav__link-main" to="/collections">Collections</NavLink> */}
                {/* <NavLink className="nav__link-main" to="/blog">Blog</NavLink> */}
                <NavLink className="nav__link-main" to="/about-us">About Us</NavLink>
                <NavLink className="nav__link-main" to="/contact-us">Contact Us</NavLink>
              </div>
              <NavLinksAsdie />
            
            </div>
        </nav>
     );
}
 
export default Navbar;