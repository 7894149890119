
import { useState } from 'react'

import { 
	RiMapPinAddLine,
	RiPhoneLine, 
	RiMailLine,
	RiInstagramLine, 
	RiWhatsappLine, 
	RiTelegramLine,
} from "react-icons/ri";

import { BiMap } from "react-icons/bi"

const ContactUs = () => {
    const [accordionStates, setAccordionStates] = useState([false, false]);

    const toggleAccordion = (index) => {
      const newAccordionStates = [...accordionStates];
      newAccordionStates[index] = !newAccordionStates[index];
      setAccordionStates(newAccordionStates);
    };
    
    const spacing = 0;

    return (
        <div className="contact">
            <div className="contact__container">
                <div className='contact__map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d749.043038772233!2d69.266033!3d41.3268697!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDE5JzM3LjAiTiA2OcKwMTUnNTguNiJF!5e0!3m2!1sru!2s!4v1698317469020!5m2!1sru!2s" width="600" height="450" style={{marginRight: 0 + 'em'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className='contact__page-wrapper'>
                    <ul className="contact__page">
    						<li className="item">
    								<div className='li__header'>
    									<h2>
    										Contact
    									</h2>
    								</div>
    							<div className="contacts">
    								<a 
    								className='contact__item'
    								href="https://www.google.com/maps/place/41%C2%B019'37.0%22N+69%C2%B015'58.6%22E/@41.3268697,69.266033,19.69z/data=!4m4!3m3!8m2!3d41.32694!4d69.266288?entry=ttu" 
    								target="_blank">
    									<div className='contact__icon-wrapper'>
    										<BiMap className="contact__icon"/>
    									</div>
    									<div>Uzbekistan, Tashkent city, 31 Shaykhontokhur street Navruz park, Tashkent street 28</div>
    								</a>
    								<a 
    								className="contact__item"
    								href='tel:+998911920888'>
    									<div className='contact__icon-wrapper'>
    										<RiPhoneLine className="contact__icon"/>
    									</div>
    									<div>+998911920888</div>
    								</a>
    								<a 
    								className="contact__item"
    								href="mailto:msurayo55@gmail.com">
    									<div className='contact__icon-wrapper'>
    										<RiMailLine className="contact__icon"/>
    									</div>
    									<div>msurayo55@gmail.com</div>
    								</a>
    							</div>
    						</li>
    						<li className="item">
    							<div className='li__header'><h2>Socials</h2></div>
    							<div className='socials'>
    								<a href="https://www.instagram.com/suraya.collection/" 
    								target="_blank" 
    								rel="noreferrer"
    								className="contact__item">
    									<div className='contact__icon-wrapper'>
    										<RiInstagramLine
    										className="contact__icon"/>
    									</div>
    									<div>Suraya.Collection</div>
    								</a>
    								<a href="" 
    								target="_blank" 
    								rel="noreferrer"
    								className="contact__item">
    									<div className='contact__icon-wrapper'>
    										<RiWhatsappLine
    										className="contact__icon"/>
    									</div>
    									<div>+998911920888</div>
    								</a>
    								<a href="https://t.me/surayacollection_uz" 
    								target="_blank" 
    								rel="noreferrer"
    								className="contact__item">
    									<div className='contact__icon-wrapper'>
    										<RiTelegramLine
    										className="contact__icon"/>
    									</div>
    									<div>Surayacollection_uz</div>
    								</a>
    							</div>
    						</li>
    					</ul>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;