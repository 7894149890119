import { useEffect, useState } from 'react';
import axios from 'axios';
import RevealOnScroll from '../components/RevealOnScroll/RevealOnScroll';
import AnimateScale from '../components/AnimateScale/AnimateScale';

import img1 from '../images/img__1.jpg'
import img2 from '../images/img__2.jpg'
import img3 from '../images/img__3.jpg'
import img4 from '../images/img__4.jpg'
import img5 from '../images/img__5.jpg'
import img6 from '../images/img__6.jpg'
// import img7 from '../images/Canon1252.webp'
import img7 from '../images/imgbanner.jpg'
import logo from '../images/Suraya_Collection_PNG-01.png'

import ProductCarousel from '../components/ProductCarousel/ProductCarousel';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from 'react-router-dom';


const Home = () => {


  const [isScaled, setIsScaled] = useState(false);

  const url = 'https://api.chapanstore.com/media/';

  const toggleScale = () => {
    setIsScaled(!isScaled);
  };


  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([])

  const addToCart = (product) => {
    setCart([...cart, product]);
  };

  useEffect(() => {
    const api = 'https://api.chapanstore.com/api/product/getAll';
  
    axios.get(api, { headers: {"Authorization" : `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
      .then((response) => {
        // Извлекаем данные из ответа
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          // Если данные существуют и это массив, устанавливаем их в состояние
          setProducts(data.data);
        } else {
          console.error('Ошибка при загрузке данных: Неверный формат данных', data);
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, []);


  const [latestBanner, setLatestBanner] = useState(null);

  useEffect(() => {
    const api = 'https://api.chapanstore.com/api/mainBanner/getAll';
  
    axios.get(api, { headers: { "Authorization": `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
      .then((response) => {
        console.log('Данные от API:', response.data);
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          const lastBanner = data.data[data.data.length - 1];
        console.log('Последний баннер:', lastBanner.image);
        setLatestBanner(lastBanner);
        } else {
          console.error('Ошибка при загрузке данных: Неверный формат данных', response.data);
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, []);


  const [imagePaths, setImagePaths] = useState({ path_1: '', path_2: '' });
  const [latestBody, setLatestBody] = useState(null);

  useEffect(() => {
    const api = 'https://api.chapanstore.com/api/mainBody/getAll';
  
    axios.get(api, { headers: { "Authorization": `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
      .then((response) => {
        console.log('Данные от API:', response.data);
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          const lastBody = data.data[data.data.length - 1]
          console.log(lastBody.title);
          setLatestBody(lastBody)
        } else {
          console.error('Ошибка при загрузке данных: Неверный формат данных', response.data);
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, []);
  

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    arrows: false,
    autoplaySpeed: 6000,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  console.log(url + latestBanner)
  


    return (
        <div className="home">
            <div className="home__container">
                {/* <h1 className="brand__name">Chapan Store</h1> */}
                {/* <div className="header__images">
                      <div className="image__main first__img scrollnimate">
                        <img src={img1} alt="img1" className="image__item"/>
                      </div>
                      <div className="image__main second__img scrollnimate">
                        <img src={img2} alt="img2" className="image__item"/>
                      </div>

                      <div className="image__main third__img scrollnimate">
                        <img src={logo} alt="img3" className="image__item"/>
                      </div>
                    
                      <div className="image__main fourth__img scrollnimate">
                        <img src={img3} alt="img4" className="image__item"/>
                      </div>

                      <div className="image__main fifth__img scrollnimate">
                        <img src={img4} alt="img4" className="image__item"/>
                      </div>

                      banners/d8063be0b6535308878f9bc356ab520fdd104a7d.jpg
                </div> */}
                <div className="banner__image">
                {latestBanner && <img src={url + latestBanner.image} alt="Latest Banner" />}

                </div>

            </div>
           
            <div className="banner__container">
                <div className="banner__first">
                  <RevealOnScroll delay={.2} transform={'translateY(40px) scale(0.5)'}>
                    
                    <h2 >{latestBody && latestBody.title }</h2>
  
                  </RevealOnScroll>
                  <RevealOnScroll delay={.3} transform={'translateY(-20px) scale(0.5)'}>
                    <p>{latestBody && latestBody.description }</p>
                    {latestBody && <img src={url + latestBody.image['path_1']} alt="Latest Banner" />}                  
                  </RevealOnScroll>
                </div>
                <div className="banner__img">
                  <RevealOnScroll delay={.4} transform={'translateY(20px) scale(1.2)'}>
                  {latestBody && <img src={url + latestBody.image['path_2']} alt="Latest Banner" />} 
                  </RevealOnScroll>
                </div>
                {/* <div className="banner__info">
                  <RevealOnScroll delay={.5} transform={'translateY(-20px) scale(0.5)'}>
                    <p>Our mission is to inspire and instill confidence, wherever you wear our garments. 
We only use the best quality silk fabric, which serve long and comfortable by wearing, because they are hand-woven by masters of their craft.</p>
                  <button>
                    <NavLink to="/shop">check out</NavLink>
                  </button>
                  </RevealOnScroll>
                 
                </div> */}
            </div>
            <ProductCarousel
                className="product__home"
                products={products}
                settings={settings}
                showImage={true}
                showTitle={true}
                showPrice={true}
                onBasketButton={addToCart}
              />
              
        </div>
    )
}

export default Home


export function authHeader() {
        return { Authorization: `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923` };
  }