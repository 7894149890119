
import { useEffect, useState } from "react";
import axios from "axios";
import { redirect } from "react-router-dom";

const API_URL = 'https://api.chapanstore.com/api/client/create'


const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true)
    const [minLengthError, setMinLengthError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [inputValid, setInputValid] = useState(false)

    useEffect(() => {
        for (const validation in validations) {
            // eslint-disable-next-line
            switch (validation) {
                case 'minLength':
                    value.length < validations.minLength ? setMinLengthError(true) : setMinLengthError(false);
                    break;
                case 'isEmpty':
                    value ? setEmpty(false) : setEmpty(true)
                    break;
                case 'isEmail': 
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    re.test(String(value).toLowerCase()) ? setEmailError(false) : setEmailError(true)
                    break;
            }
        }
        // eslint-disable-next-line
    }, [value])


    useEffect(() => {
        if(isEmpty || minLengthError || emailError) {
            setInputValid(false)
        } else {
            setInputValid(true)
        }
    }, [isEmpty, minLengthError, emailError])

    return {
        isEmpty,
        minLengthError,
        emailError,
        inputValid,
    }
}



const useInput = (intialValue, validations) => {
    const [value, setValue] = useState(intialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations)

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const onBlur = (e) => {
        setDirty(true)
    }

    return {
        value,
        onChange,
        onBlur,
        isDirty,
        ...valid
    }
}

const Registration = () => {
    const email = useInput('', {isEmpty: true, minLength: 3, isEmail: true})
    const password = useInput('', {isEmpty: true, minLength: 5, })


    const [token, setToken] = useState(null);

    const handleRegistration = (event) => {
       event.preventDefault(); // Отмена стандартного действия формы
    
        // Создайте объект данных, который будет отправлен на сервер
        const formData = new FormData();
        formData.append('name', event.target.name.value);
        formData.append('email', event.target.email.value);
        formData.append('password', event.target.password.value);
    
        // Отправьте запрос на сервер с использованием Axios
        axios.post(API_URL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`,
            }
        })
            .then((response) => {
                const token = response.data.token; 
                localStorage.setItem('token', token);
                return redirect('/home')
            })
            .catch((error) => {
                console.error('Ошибка при регистрации:', error);
            });
    };


    return (
        <div className="registration">
            <div className="registration__container">
    
                <form  onSubmit={handleRegistration} className="authModal__form">
    
                    Name
    
                    <input 
                    name="name"
                    type="text" 
                    placeholder="Type your name"
                    />
    
    
                    Email
    
                    {(email.isDirty && email.isEmpty) && <div style={{color:'red'}}>Area can't be empty</div>}
                    {(email.isDirty && email.minLengthError) && <div style={{color:'red'}}>Email is small</div>}                        {(email.isDirty && email.emailError) && <div style={{color:'red'}}>Incorrect email</div>}
    
                        <input 
                        onChange={e => email.onChange(e)} 
                        onBlur={e => email.onBlur(e)} 
                        value={email.value} 
                        name="email" 
                        type="text"
                        placeholder="Enter your email"
                        />
    
    
                    Password
    
                    {(password.isDirty && password.isEmpty) && <div style={{color:'red'}}>Area can't be empty</div>}
                    {(password.isDirty && password.minLengthError) && <div style={{color:'red'}}>Password is small</div>}
    
                        <input 
                        onChange={e => password.onChange(e)} 
                        onBlur={e => password.onBlur(e)} 
                        value={password.value} 
                        name="password" 
                        type="password" 
                        placeholder="Enter your password"
                        />
                            
                        <input 
                        disabled={!email.inputValid || !password.inputValid} 
                        type="submit" 
                        value="Registration"/>
                </form>    
                
            </div>
        </div>
    )

}

export default Registration;