import React from "react";
import { NavLink } from "react-router-dom";
import BasketButton from "../BasketButton/BasketButton";
import { useCart } from "../CartContext/CartContext";

import "./cardItem.css";

const CardItem = ({ cards, showImage, showTitle, showPrice, galleryMode }) => {
  const { addToCart, getCartIcon } = useCart(); // Используйте getCartIcon

  const handleAddToCart = (product) => {
    addToCart(product);
  };

  const url = "https://api.chapanstore.com/media/";

  return (
    <div className={`card ${galleryMode ? "gallery-mode-card" : ""}`}>
      {cards.map((product) => (
        <div className={`card__container ${galleryMode ? "gallery-mode" : ""}`} key={product.id}>
          <div className="card__content">
            <NavLink to={`/product/${product.id}`} className="card__navlink">
              <div className={`card__list-item ${galleryMode ? "gallery-mode" : ""}`}>
                <div className={`card__img-container ${galleryMode ? "gallery-mode" : ""}`}>
                  {showImage && <img className="card__img" src={url + product.image["path_1"]} alt={product.name} />}
                </div>
                <div className={`card__info-container ${galleryMode ? "gallery-mode" : ""}`} style={galleryMode ? { display: "none" } : {}}>
                  {showTitle && <h4 className="card__title">{product.name}</h4>}
                  {showPrice && <p className="card__price">Цена: {product.price === null ? "Договорная" : `${product.price} $`}</p>}
                </div>
              </div>
            </NavLink>
          </div>
          <div className="basket-button-container">
            <BasketButton product={product} children={getCartIcon(product.id)} onBasketButton={() => handleAddToCart(product)} />
          </div>
        </div>
      ))}
    </div>
  );
};


export default CardItem;
