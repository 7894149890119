

import './footer.css';
import imgLogo from '../../images/Suraya_Collection_PNG-14.png'

import { 
	RiMapPinAddLine,
	RiPhoneLine, 
	RiMailLine,
	RiInstagramLine, 
	RiWhatsappLine, 
	RiTelegramLine,
} from "react-icons/ri";

import { BiMap } from "react-icons/bi"


const Footer = () => {


    return ( 
        <footer className="footer">
			<div className="footer__container">
				<div className="footer__wrapper">
					{/* <ul className='social__header'>
						<li className='social__header-item'><img src={imgLogo} alt="imgLogo" /></li>
						<li className='social__header-item'><h2>Contact</h2></li>
						<li className='social__header-item'><h2>Socials</h2></li>
					</ul> */}
					<ul className="social">
						<li className="item">
							<div className='info__img'>
								<img src={imgLogo} alt="imgLogo" />
							</div>
							<div className='info__text'>
								<p>Our showroom is located in the Navruz ethnographic park, Tashkent street 28. We are always glad to welcome our dear guests - buyers and partners in our cozy and fabulous showroom by prior call. The doors of our Eastern world are open on weekdays from 9.00 to 18.00. Sunday is a day of.</p>
							</div>
						</li>
						<li className="item">
								<div className='li__header'>
									<h2>
										Контакты
									</h2>
								</div>
							<div className="contacts">
								<a 
								className='contact__item'
								href="https://www.google.com/maps/place/41%C2%B019'37.0%22N+69%C2%B015'58.6%22E/@41.3268697,69.266033,19.69z/data=!4m4!3m3!8m2!3d41.32694!4d69.266288?entry=ttu" 
								target="_blank">
									<div className='contact__icon-wrapper'>
										<BiMap className="contact__icon"/>
									</div>
									<div>Uzbekistan, Tashkent city, 31 Shaykhontokhur street Navruz park, Tashkent street 28</div>
								</a>
								<a 
								className="contact__item"
								href='tel:+998957701030'>
									<div className='contact__icon-wrapper'>
										<RiPhoneLine className="contact__icon"/>
									</div>
									<div>+998-95-770-10-30</div>
								</a>
								<a 
								className="contact__item"
								href="mailto:suraya.collection.ikat@gmail.com">
									<div className='contact__icon-wrapper'>
										<RiMailLine className="contact__icon"/>
									</div>
									<div className='email__footer'>suraya.collection.ikat@gmail.com</div>
								</a>
							</div>
						</li>
						<li className="item">
							<div className='li__header'><h2>Медиа</h2></div>
							<div className='socials'>
								<a href="https://www.instagram.com/suraya.collection/" 
								target="_blank" 
								rel="noreferrer"
								className="contact__item">
									<div className='contact__icon-wrapper'>
										<RiInstagramLine
										className="contact__icon"/>
									</div>
									<div>Suraya.Collection</div>
								</a>
								<a href="" 
								target="_blank" 
								rel="noreferrer"
								className="contact__item">
									<div className='contact__icon-wrapper'>
										<RiWhatsappLine
										className="contact__icon"/>
									</div>
									<div>+998-95-770-10-30</div>
								</a>
								<a href="https://t.me/surayacollection_uz" 
								target="_blank" 
								rel="noreferrer"
								className="contact__item">
									<div className='contact__icon-wrapper'>
										<RiTelegramLine
										className="contact__icon"/>
									</div>
									<div>Surayacollection_uz</div>
								</a>
							</div>
						</li>
					</ul>
					<div className="copyright">

					</div>
				</div>
			</div>
		</footer>
     );
}
 
export default Footer;