import { useState, useEffect } from "react";
import axios from "axios";
import CardItem from "../components/CardItem/CardItem";
import CategoryModal from "../CategoryModal/CategoryModal";
import { useNavigate } from "react-router-dom";
import { useCategory } from "../components/CategoryContext/CategoryContext";

const API_BASE_URL = 'https://api.chapanstore.com/api';
const API_URL = `${API_BASE_URL}/product/getByCategory`;

// const Shop = () => {
//   const [cart, setCart] = useState([]);
//   const [products, setProducts] = useState([]);
  // const [categories, setCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState(
  //   categories.length > 0 ? categories[0].id : null
  // );
//   const [galleryMode, setGalleryMode] = useState(false);

//   const navigate = useNavigate();

//   const toggleGalleryMode = () => {
//     setGalleryMode(!galleryMode);
//   };

//   const addToCart = (product) => {
//     setCart([...cart, product]);
//   };

//   const loadCategories = () => {
//     axios
//       .get("https://api.chapanstore.com/api/productCategory/getAll", {
//         headers: { Authorization: `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923` },
//       })
//       .then((response) => {
//         const data = response.data;
//         if (data && data.data && Array.isArray(data.data)) {
//           setCategories(data.data);
//         } else {
//           console.error("Ошибка при загрузке категорий: Неверный формат данных", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Ошибка при загрузке категорий:", error);
//       });
//   };

//   const loadProducts = () => {
//     if (!selectedCategory) {
//       console.error("Не выбрана категория");
//       return;
//     }
  
//     const apiUrl = `${API_URL}/${selectedCategory}`;
  
//     console.log("Sending request to:", apiUrl); // Добавим эту строку для отладки
  
//     axios
//       .get(apiUrl, {
//         headers: { Authorization: `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923` },
//       })
//       .then((response) => {
//         console.log("Response data:", response.data); // Добавим эту строку для отладки
  
//         const data = response.data;
//         if (data && data.data && Array.isArray(data.data)) {
//           setProducts(data.data);
//         } else {
//           console.error("Ошибка при загрузке товаров: Неверный формат данных", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Ошибка при загрузке товаров:", error);
//       });
//   };
  

//   const handleCategorySelect = (category) => {
//     console.log("Selected Category:", category.id);
//     setSelectedCategory(category.id);
//     loadProducts();
//   };
  

//   useEffect(() => {
//     loadCategories();
//     loadProducts();
//   }, []);

//   console.log(setSelectedCategory);
//   return (
//     <div className="shop">
//       <div className="shop__container">
//         <div className="shop__category-bar">
//           <CategoryModal categories={categories} onCategorySelect={handleCategorySelect} />
//         </div>

//         <div>
//           <CardItem
//             cards={products}
//             showImage={true}
//             showTitle={!galleryMode}
//             showPrice={false}
//             onBasketButton={addToCart}
//             galleryMode={galleryMode}
//             />
//         </div>
//       </div>
//     </div>
//   );
// };
            
// export default Shop;





// import { useState, useEffect } from "react";
// import axios from "axios";
// import { RiFunctionLine } from "react-icons/ri";


// import CardItem from "../components/CardItem/CardItem";

// const API_URL = 'https://api.chapanstore.com/api/product/getAll'

const Shop = () => {
  const [categories, setCategories] = useState([]);
  const { selectedCategory } = useCategory();
  const [galleryMode, setGalleryMode] = useState(false);

  const toggleGalleryMode = () => {
    setGalleryMode(!galleryMode);
  };
  
  
  const [cart, setCart] = useState([])
  const [products, setProducts] = useState([]);
  
  const addToCart = (product) => {
    setCart([...cart, product]);
  };


  const initialSelectedCategory = localStorage.getItem('selectedCategory') || 1;


  const apiUrl = `${API_URL}/${selectedCategory || initialSelectedCategory}`;



  useEffect(() => {
    // Обновление списка товаров при изменении выбранной категории
    axios.get(apiUrl, { headers: {"Authorization" : `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
      .then((response) => {
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          setProducts(data.data);
        } else {
          console.error('Ошибка при загрузке данных: Неверный формат данных', data);
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, [selectedCategory]);

  // Восстановление выбранной категории при обновлении страницы
  useEffect(() => {
    const storedCategory = localStorage.getItem('selectedCategory');
    if (storedCategory) {
      // При необходимости, здесь можно выполнить дополнительные действия
      // в связи с изменением категории
    }
  }, []);

  // Сохранение выбранной категории в localStorage
  useEffect(() => {
    localStorage.setItem('selectedCategory', selectedCategory);
  }, [selectedCategory]);

  
  useEffect(() => {
    
    axios.get(apiUrl, { headers: {"Authorization" : `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
    .then((response) => {
      const data = response.data;
      if (data && data.data && Array.isArray(data.data)) {
        setProducts(data.data);
      } else {
        console.error('Ошибка при загрузке данных: Неверный формат данных', data);
      }
    })
    .catch((error) => {
      console.error('Ошибка при загрузке данных:', error);
    });
  }, []);
  
  
  
  return (
    <div className="shop">
            <div className="shop__container">
                <div className="btn__container">
                  <button onClick={() => setGalleryMode(!galleryMode)} className="btn__gallery">
                    {/* {galleryMode ? "Switch to List View" : "Switch to Gallery View"} */}
                    {/* <RiFunctionLine></RiFunctionLine> */}
                  </button>
                </div>

                <div>
                    <CardItem
                    cards={products}
                    showImage={true}
                    showTitle={!galleryMode}
                    showPrice={true}
                    onBasketButton={null}
                    galleryMode={galleryMode}
                    />
                </div>

            </div>
        </div>
    )
  }
  
  export default Shop;

  
  
  // const [galleryMode, setGalleryMode] = useState(false);

  // const toggleGalleryMode = () => {
  //   setGalleryMode(!galleryMode);
  // };
  
  
//   {/* <button onClick={() => setGalleryMode(!galleryMode)} className="btn__gallery"> */}
