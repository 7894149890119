import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GoBookmark } from "react-icons/go";
import { useCart } from "../components/CartContext/CartContext";
import axios from "axios";

import BasketButton from "../components/BasketButton/BasketButton";
import AsNavFor from "../components/AsNavFor/AsNavFor";

const API_URL = 'https://api.chapanstore.com/api/product/getBy';

const ProductMainPage = () => {
    const { id } = useParams(); // Извлекаем id товара из URL

    const { addToCart, getCartIcon } = useCart(); // Получите доступ к функции addToCart из контекста корзины

    const handleAddToCart = (product) => {
      addToCart(product);
    };

    const [product, setProduct] = useState(null);

    const [images, setImages] = useState(null);

    useEffect(() => {
        axios.get(`${API_URL}/${id}`, { headers: {"Authorization" : `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
          .then((response) => {
            const data = response.data;
            if (data && data.data) {
              setProduct(data.data);
              setImages(data.data.images)       
            } else {
              console.error('Ошибка при загрузке данных: Неверный формат данных', data);
            }
          })
          .catch((error) => {
            console.error('Ошибка при загрузке данных:', error);
          });
    }, [id]);

    if (!product) {
        return <div>Loading...</div>;
    }
    console.log(Object.keys(product.image).map((key) => (
      product.image[key]
    )));
    console.log(Object.values(product.image));
    const url = 'https://api.chapanstore.com/media/';

    return (
        <div className="product__main">
          <h2 className="product__title">{product.name}</h2>
          <div className="product__carouse-container">
            <AsNavFor images={Object.values(product.image)}></AsNavFor>
          </div>

            <div className="product__container" key={product.id}>
                {/* <div className="product__list-item" key={product.id}>
                <div className="product__img-container">
                    {Object.keys(product.image).map((key) => (
                      <img
                        className="product__img"
                        key={key}
                        src={url + product.image[key]}
                        alt={product.name}
                      />
                    ))}
                    <div className="product__card-menu"></div>
                  </div>
                </div> */}
                <div className="product__info-container">
                    
                   
                    <p className="product__category">Категория: {product.category}</p>
                    <p className="product__info">Описание: {product.description}</p>
                     <div className="product__price-basket">
                        
                     <BasketButton product={product} children={getCartIcon(product.id)} onBasketButton={handleAddToCart} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductMainPage;
