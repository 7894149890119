import { useCart } from "../components/CartContext/CartContext";
import { useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import SuccesModal from "../components/SuccesModal/SuccesModal";
// import useDisclosure from "../components/useDisclosure/useDisclosure";


const url = 'https://api.chapanstore.com/media/'


const CheckOut = () => {
  
  const { cart, clearCart } = useCart();

  // const { isOpen, open, close } = useDisclosure();
  const [show, showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useNavigate();
  // const formRef = useRef(null)
  // const fileInputRef = useRef(null);

  // const handleCloseModal = () => {
  //   // setShowModal(false);
  //   close()
  //   history("/"); // Перенаправление пользователя после закрытия модального окна
  // };


  async function handleSubmit(event) {
    event.preventDefault();
    

    const formData = new FormData();


    // formData.append("product_id", 1);
    cart.forEach(product => {
      formData.append("product_id", product.id);
    });
    formData.append("email", event.target.email.value);
    formData.append("client_name", event.target.client_name.value);
    formData.append("social", event.target.social.value);

    setIsSubmitting(true);

    console.log(formData);

    // try {
    //   const response = await axios.post(
    //     "https://api.chapanstore.com/api/order/create", 
    //     formData, 
    //   { 
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Authorization" : `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} 
    //   });
    //   if (response.data.statusCode === 200) {
    //     const data = response.data;
    //     if (data && data.data && Array.isArray(data.data)) {
    //       // setProducts([data.data]);
    //       console.log('Successful upload');
    //     } else {
    //       console.log('ERROR');
    //       // setShowModal(true)
    //       // open()
    //       // clearCart()
    //       // formRef.current.reset();
    //       // fileInputRef.current.value = "";
    //       history("/"); 

    //     }
    //   } else {
    //     console.log(`Response: ${response.status}`);
    //   }
    // } catch (error) {
    //   console.error(error);
    //   // Обработка ошибки, если запрос не удался
    // }
    // }

    axios
      .post("https://api.chapanstore.com/api/order/create", formData, { headers: 
        {
          "Content-Type": "application/json",
          "Authorization" : `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
      .then((response) => {
        console.log("Заказ успешно оформлен", response.data);
        // clearCart(); // Очистить корзину после успешного заказа
        history("/order-success"); // Перейти на страницу успешного заказа
      })
      .catch((error) => {
        console.error("Ошибка при оформлении заказа", error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="checkout__wrapper">
      <h1>Оформление заказа</h1>
      <ul className="checkout__container">
        {cart && cart.map((product) => (
          <li className="checkout__list-item" key={product.id}>
            <div className="checkout__img-container">
              <img className="checkout__img" src={url + product.image['path_1']} alt={product.name} />
            </div>
            <p className="checkout__title">{product.name}</p>
            {/* <p className="checkout__price">Цена: {product.price}</p> */}
          </li>
        ))}
      </ul>

      <form className="checkout__form" onSubmit={handleSubmit}>
      {/* <form ref={formRef} className="checkout__form" onSubmit={handleSubmit}> */}
        <label>
          Имя:
          <input placeholder="Ваше Имя и Фамилия" type="text" name="client_name" />
        </label>
        <label>
          Email:
          <input placeholder="Ваш Еmail Адресс" type="email" name="email" />
        </label>
        <label>
          Telegram/Номер телефона:
          <input placeholder="Никнейм/Номер" type="text" name="social" />
        </label>
        <input type="submit" value="Заказать"/>
      </form>


      {/* {showModal && (
                <SuccesModal show={isOpen} onClose={handleCloseModal}>
                    <p>Ура товар оформлен</p>
                </SuccesModal>
            )} */}
    </div>
  );
};

export default CheckOut;




// import { useCart } from "../components/CartContext/CartContext";
// import { useState } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const url = 'https://api.chapanstore.com/media/'

// const CheckOut = () => {
//   const { cart, clearCart } = useCart();

//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const history = useNavigate();


//   const handleSubmit = (event) => {
//     event.preventDefault();
    

//     const formData = new FormData();

//     cart.forEach(product => {
//       formData.append("product_ids[]", product.id);
//     });
//     formData.append("email", event.target.email.value);
//     formData.append("client_name", event.target.client_name.value);
//     formData.append("social", event.target.social.value);

//     // setIsSubmitting(true);
//     console.log(formData);

//     axios
//       .post("https://api.chapanstore.com/api/order/create", formData, { headers: 
//         {
//           "Content-Type": "application/json",
//           "Authorization" : `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
//       .then((response) => {
//         console.log("Заказ успешно оформлен", response.data);
//         // clearCart(); // Очистить корзину после успешного заказа
//         history("/order-success"); // Перейти на страницу успешного заказа
//       })
//       .catch((error) => {
//         console.error("Ошибка при оформлении заказа", error);
//       })
//       // .finally(() => {
//       //   setIsSubmitting(false);
//       // });
//   };

//   return (
//     <div className="checkout__wrapper">
//       <h1>Оформление заказа</h1>
//       <ul className="checkout__container">
//         {cart && cart.map((product) => (
//           <li className="checkout__list-item" key={product.id}>
//             <div className="checkout__img-container">
//               <img className="checkout__img" src={url + product.image['path_1']} alt={product.name} />
//             </div>
//             <p className="checkout__title">{product.name}</p>
//             {/* <p className="checkout__price">Цена: {product.price}</p> */}
//           </li>
//         ))}
//       </ul>

//       <form className="checkout__form" onSubmit={handleSubmit}>
//         <label>
//           Имя:
//           <input placeholder="Ваше Имя и Фамилия" type="text" name="client_name" />
//         </label>
//         <label>
//           Email:
//           <input placeholder="Ваш Еmail Адресс" type="email" name="email" />
//         </label>
//         <label>
//           Telegram/Номер телефона:
//           <input placeholder="Никнейм/Номер" type="text" name="social" />
//         </label>
//         <input type="submit" value="Заказать"/>
//       </form>
//     </div>
//   );
// };

// export default CheckOut;