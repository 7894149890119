import aboutImg1 from '../images/about__1.jpg'
import aboutImg2 from '../images/about__2.jpg'
import { useState } from 'react'


const AboutUs = () => {
    const [accordionStates, setAccordionStates] = useState([false, false]);

    const toggleAccordion = (index) => {
      const newAccordionStates = [...accordionStates];
      newAccordionStates[index] = !newAccordionStates[index];
      setAccordionStates(newAccordionStates);
    };
    
    const accordionData = [
        {
          title: "HOW TO WEAR A CHAPAN?",
          content: (
            <>
              <p>Chapans are a universal piece of clothing, they can be worn with anything, this is their feature! They are short and long. Insulated and very light, summer models. Chapan is ideally combined with both casual wear and evening dresses.</p>
              <p>Style your chapan with black skinny jeans, a basic top and sneakers. Try wearing a hoodie and blue mom jeans underneath the chapan. We guarantee that all eyes will be on you.</p>
              <p>Jeans of any color and style, chinos, tops, shirts and dresses will look harmoniously paired with a chapan.</p>
              <p>If you are planning to visit an official event, then you can wear a chapan over an evening dress, and you will definitely look dazzling and stylish.</p>
            </>
          ),
        },
        {
          title: "CARE",
          content: (
            <>
              <p>Natural silk requires delicate and gentle care. It is best to treat your chapan the same way you treat an expensive coat.</p>
              <p>Chapan and all products made of silk adras are not recommended to be washed by hand or in a washing machine and ironed at a very high temperature.</p>
              <p>Proper care of silk and it will delight you for a long time with its rich color and unique pattern, as well as delicate fabric.</p>
            </>
          ),
        },
      ];

    return (
        <div className="about">
            <div className="about__container">
                <h2 className='about__head-title'>About Us</h2>
                <div className="about__first-banner">
                    <div className="about__info">
                        <div className="about__title">
                            <h1>STYLIZED UZBEK CHAPAN</h1>
                        </div>
                        <div className="about__text">
                            <p>Chapan is a cult piece of clothing, it harmoniously combines the history and culture of the ancient people. Each product embodies centuries-old traditions, folk weaving and sewing techniques, adapted to modern conditions.</p>
                            <p>Suraya.collection is a modern clothing brand that was founded in Tashkent. Our brand supports sustainable development, we work with natural and environmentally friendly materials, focus on sustainable production, and support local artisans.</p>
                            <p>The coolness of alluring oases, the hot sands of the desert, blossoming almonds and juicy peaches have become the main sources of inspiration for the creation of collections. A rich color palette, bright colors, free cut and non-trivial combinations of fabrics - all this is harmoniously combined in our brand. Buy clothes from our collections and go on a journey through the oriental tales of "1001 Nights".</p>
                        </div>
                    </div>
                    <div className="about__images">
                        <img className="about__images-main" src={aboutImg1} alt="aboutimg1" />
                    </div>
                    
                </div>
                <div className="about__first-banner">
                    <div className="about__images">
                        <img className="about__images-main" src={aboutImg2} alt="aboutimg1" />
                    </div>
                    <div className="about__info">
                        <div className="about__title">
                            <h1>BRAND HISTORY</h1>
                        </div>
                        <div className="about__text">
                            <p>Suraya.collection was created in 2015. The founder of the brand, Suraya, went to buy ikat for her daughter's dowry, returning home from the store she simply could not take her eyes off the luxurious fabrics. They delighted the eye and fascinated, giving rise to numerous images of clothing models in my head. It was then that the idea was born to create a clothing brand from magnificent Uzbek fabrics, that is, to sew stylish items of clothing for everyday life. A brand that, while preserving tradition and history, will offer luxury, unique handmade items to women around the world.</p>
                        </div>
                        {accordionData.map((data, index) => (
                            <div className="about__accordion" key={index}>
                                <div className="about__accordion-header" onClick={() => toggleAccordion(index)}>
                                <h3>{data.title}</h3>
                                <span className={`about__accordion-toggle ${accordionStates[index] ? 'open' : ''}`}>{accordionStates[index] ? '-' : '+'}</span>
                                </div>
                                <div className={`about__accordion-content ${accordionStates[index] ? 'open' : ''}`}>
                                {data.content}
                                </div>
                            </div>
                        ))}
                    </div>
                      
                </div>
            </div>
        </div>
    )
}

export default AboutUs;