import './cartModal.css'

import Modal from "../Modal/Modal";

import { useNavigate } from 'react-router-dom';
import { useCart } from '../CartContext/CartContext';

const CartModal = ({ cart, active, setActive }) => {
    // console.log(active);
    // console.log(useCart());
    const navigate = useNavigate()
    
    const { removeFromCart } = useCart()
    
    

    const handleNavigation = () => {
      setActive(false)
      navigate('/checkout')
    }

    const url = 'https://api.chapanstore.com/media/'

    return (
      <Modal  active={active} setActive={setActive}>
          <div className="cart-modal">
            <h2>Cart</h2>
            <ul className='cart__modal-list'>
              {cart.map((product) => (
                 
                <li className="cart__modal-main" key={product.id}>
                  {console.log(product.id)}
    {product.image && <img className="cart__modal-img" src={url + product.image['path_1']} alt={product.name} />}
                  <p className='cart__modal-title'>{product.name}</p>
                  <button onClick={() => removeFromCart(product.id)}>Delete</button>
                </li>
              ))}
            </ul>
          </div>
          <button
              onClick={handleNavigation}
              style={{ display: cart.length > 0 ? 'block' : 'none' }}
              >
              Buy clothes
          </button>

      </Modal>
    );
  };
  
  export default CartModal;