import React from 'react';

function Success() {
  return (
    <div className="success">
      <header className="success-header">
      <h1>Поздравляем с Успешной Покупкой!</h1>
        <p>Мы очень рады, что вы выбрали нас. Ваш заказ обрабатывается с любовью и вниманием.</p>
        <p>Скоро мы свяжемся с вами по указанному телефонному номеру для подтверждения деталей доставки.</p>
        <p>Спасибо за доверие и желаем вам приятного дня!</p>
      </header>
    </div>
  );
}

export default Success;
