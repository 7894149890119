
import {GoBookmark} from "react-icons/go"
const BasketButton = ({product, onBasketButton, children, fontWeight}) => {
    const handleBasketButton = () => {
        onBasketButton(product)
    };

    return (
        <button onClick={handleBasketButton} fontWeight={fontWeight}>
            
                {children}
            
        </button>
    );
};

export default BasketButton