import "./burgerModal.css";

const BurgerModal = ({ active, setActive, children, onClose }) => {

  return (
    <div
      className={active ? 'modal active' : "modal"}
      onClick={() => setActive(false)}
    >
      <div
        className={active ? 'modal__content active' : "modal__content"}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={active ? 'modal__children active' : "modal__children"}>
          {children}
        </div>
        <button
          className={onClose ? 'modal__close' : 'modal__close active'}
          onClick={() => setActive(false)}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
};

export default BurgerModal;