


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';
import { CartProvider } from "./components/CartContext/CartContext";

import Navbar from "./components/Navbar/Navbar";
import Home from "./Pages/Home.js"
import CheckOut from "./Pages/CheckOut.js"
import Shop from "./Pages/Shop";
import Registration from "./Pages/Registration";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import Footer from "./components/footer/Footer";
import ProductMainPage from "./Pages/ProductMainPage";
import Success from "./Pages/SuccessPage.js";
import ProductsByCategory from "./Pages/ProductsByCategory";
import { CategoryProvider } from "./components/CategoryContext/CategoryContext.js";


import './components/Styles/main.css'

function App() {
    return (
        <CartProvider>
<CategoryProvider>
    
                <div className="App">
                    <ParallaxProvider>
                   <Router>
                        <Navbar />
                        <Routes>
                            <Route path="/" element={ <Home /> }/>
                            <Route path="/checkout" element={ <CheckOut /> }/>
                            <Route path="/order-success" element={ <Success /> }/>
                            <Route path="/shop" element={ <Shop /> }/>
                            <Route path="/registration" element={ <Registration /> }/>
                            <Route path="/about-us" element={ <AboutUs /> }/>
                            <Route path="/contact-us" element={ <ContactUs /> }/>
                            <Route path="/product/:id" element={<ProductMainPage/>}/>
                            <Route path="/products/:categoryId" element={<Shop />} />
                        </Routes>
                   </Router>
                    </ParallaxProvider>
                    <Footer/>
                </div>
                
</CategoryProvider>
        </CartProvider>
    );
}

export default App;
