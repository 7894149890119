
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "../CartContext/CartContext";
import { NavLink } from "react-router-dom";
import axios from "axios";

import AuthModal from "../AuthModal/AuthModal";
import CartModal from "../CartModal/CartModal";
import BurgerModal from "../BurgerModal/BurgerModal";

import "./navLinksAside.css"
import CategoryModal from "../../CategoryModal/CategoryModal";
import { useCategory } from "../CategoryContext/CategoryContext"


const API_URL = 'http://api.chapanstore.com/api/auth/signIn'



const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true)
    const [minLengthError, setMinLengthError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [inputValid, setInputValid] = useState(false)
    // const [selectedCategory, setSelectedCategory] = useState(null);
    const { setSelectedCategory } = useCategory();


    useEffect(() => {
        for (const validation in validations) {
            // eslint-disable-next-line
            switch (validation) {
                case 'minLength':
                    value.length < validations.minLength ? setMinLengthError(true) : setMinLengthError(false);
                    break;
                case 'isEmpty':
                    value ? setEmpty(false) : setEmpty(true)
                    break;
                case 'isEmail': 
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    re.test(String(value).toLowerCase()) ? setEmailError(false) : setEmailError(true)
                    break;
            }
        }
        // eslint-disable-next-line
    }, [value])


    useEffect(() => {
        const storedCategory = localStorage.getItem('selectedCategory');
        if (storedCategory) {
          setSelectedCategory(Number(storedCategory));
        }
      }, [setSelectedCategory]);
    


    useEffect(() => {
        if(isEmpty || minLengthError || emailError) {
            setInputValid(false)
        } else {
            setInputValid(true)
        }
    }, [isEmpty, minLengthError, emailError])

    return {
        isEmpty,
        minLengthError,
        emailError,
        inputValid,
    }
}



const useInput = (intialValue, validations) => {
    const [value, setValue] = useState(intialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations)

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const onBlur = (e) => {
        setDirty(true)
    }

    return {
        value,
        onChange,
        onBlur,
        isDirty,
        ...valid
    }
}


const NavLinksAsdie = (category) => {
    const { selectedCategory, setSelectedCategory } = useCategory();

    const token = localStorage.getItem('token');

if (token) {
  console.log('Пользователь авторизован');
  console.log(token);
  
} else {
  console.log('Пользователь не авторизован');
//   console.log(token);
}

    const email = useInput('', {isEmpty: true, minLength: 3, isEmail: true})
    const password = useInput('', {isEmpty: true, minLength: 5, })

    // const API_URL = 'http://api.chapanstore.com/api/auth/signIn'
    const API_CATEGORIES_URL = 'https://api.chapanstore.com/api/productCategory/getAll';



    const handleLogin = (event) => {
        event.preventDefault()


        const formData = new FormData();
        formData.append('email', event.target.email.value);
        formData.append('password', event.target.password.value);

        console.log(formData);
        axios.post(API_URL, formData, {
            headers: {
                'Authorization': `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`,
            }
        })
            .then((response) => {
                const token = response.data.token; 
                localStorage.setItem('token', token);
            })
            .catch((error) => {
                // Обработка ошибки
                console.error('Ошибка при авторизации:', error);
            });
    };

    const navigate = useNavigate();

    const navigateToAnotherPage = () => {
        navigate('/checkout');
        setCartModalActive(false)
    }

    const isUserAuthenticated = localStorage.getItem('token');

    const [authModalActive, setAuthModalActive] = useState(false)
    const [cartModalActive, setCartModalActive] = useState(false);
    const [burgerModalActive, setBurgerModalActive] = useState(false)

    const closeAllModals = () => {
        setAuthModalActive(false);
        setCartModalActive(false);
        setBurgerModalActive(false);
    };

    const toggleAuthModal = () => {
        setAuthModalActive(!authModalActive);
        if (cartModalActive || burgerModalActive) {
            closeAllModals();
        }
        if (isUserAuthenticated) {
            localStorage.removeItem('token');
        }
    };

    const toggleCartModal = () => {
        setCartModalActive(!cartModalActive);
        if (authModalActive || burgerModalActive) {
            closeAllModals();
        }
    };

    const toggleBurgerModal = () => {
        setBurgerModalActive(!burgerModalActive);
        if (authModalActive || cartModalActive) {
            closeAllModals();
        }
    };

    const [showNav, setShowNav] = useState(false);

    const toggleNav = () => {
      setShowNav(!showNav);
    };
      
  const { cart } = useCart(); 

  const [categories, setCategories] = useState([]);
  const [showCategories, setShowCategories] = useState(false);

  useEffect(() => {
    const api = 'https://api.chapanstore.com/api/productCategory/getAll';

    axios.get(api, {
      headers: {
        Authorization: `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`
      }
    })
      .then((response) => {
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          setCategories(data.data);
        } else {
          console.error('Ошибка при загрузке данных: Неверный формат данных', data);
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, []);

  const handleShopButtonClick = () => {
    // Toggle the state to show/hide categories
    setShowCategories(!showCategories);
    // Очистим выбранную категорию, если категории скрыты
    if (!showCategories) {
      setSelectedCategory(null);
    }
  };
  const handleCategorySelect  = (category) => {
    setSelectedCategory(category.id);
    localStorage.setItem('selectedCategory', category.id);

    // Дополнительные действия при выборе категории, если необходимо
  };
  const handleCategoryClick = (category) => {
    setSelectedCategory(category.id);
    localStorage.setItem('selectedCategory', category.id);
}



    return (
        <div className="nav__link-aside">
               


                <button onClick={toggleCartModal}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M253.3 35.1c6.1-11.8 1.5-26.3-10.2-32.4s-26.3-1.5-32.4 10.2L117.6 192H32c-17.7 0-32 14.3-32 32s14.3 32 32 32L83.9 463.5C91 492 116.6 512 146 512H430c29.4 0 55-20 62.1-48.5L544 256c17.7 0 32-14.3 32-32s-14.3-32-32-32H458.4L365.3 12.9C359.2 1.2 344.7-3.4 332.9 2.7s-16.3 20.6-10.2 32.4L404.3 192H171.7L253.3 35.1zM192 304v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V304c0-8.8 7.2-16 16-16s16 7.2 16 16zm96-16c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V304c0-8.8 7.2-16 16-16zm128 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V304c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>
                </button>
                <CartModal cart={cart} active={cartModalActive} setActive={setCartModalActive}/>


                <button onClick={toggleBurgerModal}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
                </button>

                <BurgerModal active={burgerModalActive} setActive={setBurgerModalActive}>
                    <div className="burger__menu">
                        <NavLink className="nav__link-main-aside" to="/">Главная</NavLink>
                        <div className="nav__link-main-aside" onClick={handleShopButtonClick}>
                           Категории
                        </div>
                        
                        {categories.map((category) => (
    <li key={category.id}>
        <NavLink
            to={`/products/${category.id}`}
            onClick={() => handleCategoryClick(category)}
        >
            {category.name}
        </NavLink>
    </li>
))}



                        {/* <CategoryModal></CategoryModal> */}
                        <NavLink className="nav__link-main-aside" to="/about-us">О нас</NavLink>
                        <NavLink className="nav__link-main-aside" to="/contact-us">Контакты</NavLink>


                        {/* <button className="auth_burger" onClick={toggleAuthModal}>
                            {authModalActive ? 
                                <div className="modal__registration">
                                    <NavLink to="/registration">Register</NavLink>
                                </div>
                            : 'Login/Register'}
                        </button> */}

                        
                    </div>
                </BurgerModal>

                {/* <AuthModal active={authModalActive} setActive={setAuthModalActive} className="authModal">
                    <form  onSubmit={handleLogin} className="authModal__form">

                        Email

                        {(email.isDirty && email.isEmpty) && <div style={{color:'red'}}>Area can't be empty</div>}
                        {(email.isDirty && email.minLengthError) && <div style={{color:'red'}}>Email is small</div>}
                        {(email.isDirty && email.emailError) && <div style={{color:'red'}}>Incorrect email</div>}

                        <input 
                        onChange={e => email.onChange(e)} 
                        onBlur={e => email.onBlur(e)} 
                        value={email.value} 
                        name="email" 
                        type="text" 
                        placeholder="Enter ypur email"
                        />


                        Password

                        {(password.isDirty && password.isEmpty) && <div style={{color:'red'}}>Area can't be empty</div>}
                        {(password.isDirty && password.minLengthError) && <div style={{color:'red'}}>Password is small</div>}

                        <input 
                        onChange={e => password.onChange(e)} 
                        onBlur={e => password.onBlur(e)} 
                        value={password.value} 
                        name="password" 
                        type="password" 
                        placeholder="Enter your password"
                        />
                        
                        <input disabled={!email.inputValid || !password.inputValid} type="submit" value="Login"/>
                    </form>
              </AuthModal> */}
              </div>
    )
}

export default NavLinksAsdie;
