import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components';

const RevealWrapper = styled.div`
  position: relative;
  opacity: 0;
  transform: ${props => props.transfrom || 'translateY(-20px) scale(0.8)'}; /* Начальная позиция */
  transition: opacity 0.5s ease, transform 0.5s ease;
  &.visible {
      opacity: 1;
      transform: translateY(0) scale(1); /* Конечная позиция */
      transition-delay: ${props => props.delay || 0}s;
  }
`;

const RevealOnScroll = ({ children, delay, transform }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const onWindowScroll = () => {
      const element = ref.current;
      if (element) {
        const { top } = element.getBoundingClientRect();
        const shouldBeVisible = top < window.innerHeight;
        setIsVisible(shouldBeVisible);
      }
    };

    window.addEventListener("scroll", onWindowScroll);

    return () => {
      window.removeEventListener("scroll", onWindowScroll);
    };
  }, []);

  return (
    <RevealWrapper className={isVisible ? "visible" : ""} delay={delay} transform={isVisible ? 'translateY(0) scale(1)' : transform}>
      <div ref={ref}>{children}</div>
    </RevealWrapper>
  );
};

export default RevealOnScroll;