
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const url = 'https://api.chapanstore.com/media/';

const AsNavFor = ({ images }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  useEffect(() => {
    setNav1(nav1);
    setNav2(nav2);
  }, []);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    fade: true,
    asNavFor: nav2,
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: nav1,
    dots: false,
    centerMode: true,
    // vertical: true,
    // verticalSwiping: true,
    focusOnSelect: true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  return (
    <div className="product__carousel">
      {/* <h2>Slider Syncing (AsNavFor)</h2> */}
      <div className="product__image">
          <Slider {...settingsMain} ref={(slider) => setNav1(slider)}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={url + image} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </Slider>
      </div>
      <Slider className="product__carousel-item"
      {...settingsThumbs} ref={(slider) => setNav2(slider)}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={url + image} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AsNavFor;
